<script setup lang="ts">
import { ref, watch } from 'vue'

import GlobImage from '@/components/GlobImage.vue';

import type { CanvasFilter, RulesSet } from '=/types'
import type { Form } from '@/services/form'

const values = defineModel<Form<CanvasFilter>>('values', { required: true })

defineProps<{ rules?: RulesSet }>()

const dropdown = ref<boolean>(false)

watch(values.value.form.value.joint, () => dropdown.value = !dropdown.value, { deep: true })
</script>

<template>
  <p class="config__text">Stel hier de specificaties voor de voeg in</p>
  <div class="config__form two">
    <p class="title">Diepte</p>
    <label class="select">
      <input type="radio" name="inset" :value="false" v-model="values.form.value.joint.inset" />
      Vol
    </label>
    <label class="select">
      <input type="radio" name="inset" :value="true" v-model="values.form.value.joint.inset" />
      Verdiept
    </label>
  </div>
  <div class="config__form five">
    <p class="title">Stootvoeg</p>
    <template v-for="(x, index) in rules?.joints.spacing.x" :key="index">
      <label class="select">
        <input type="radio" name="spacing.x" :value="x" v-model="values.form.value.joint.spacing.x" />
        {{ x }}mm
      </label>
    </template>
  </div>
  <div class="config__form five">
    <p class="title">Lintvoeg</p>
    <template v-for="(y, index) in rules?.joints.spacing.y" :key="index">
      <label class="select">
        <input type="radio" name="spacing.y" :value="y" v-model="values.form.value.joint.spacing.y" />
        {{ y }}mm
      </label>
    </template>
  </div>
  <div class="config__form" v-outside="() => (dropdown = false)">
    <p class="title">Kleur</p>
    <button class="grout-color" @click="dropdown = !dropdown">
      <GlobImage folder="previews" :name="values.form.value.joint.type.file" class="color" />
      {{ values.form.value.joint.type.name ?? 'Selecteer een voegkleur' }}
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.5 5.5">
        <path fill="#78716C"
          d="M.22.22a.75.75 0 0 1 1.06 0l3.47 3.469L8.22.219a.75.75 0 0 1 .976-.072L9.28.22a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z" />
      </svg>
    </button>
    <div class="dropdown" :class="{ open: dropdown }">
      <div v-if="dropdown">
        <template v-for="(joint, index) in rules?.joints.type" :key="index">
          <label class="dropdown__item">
            <GlobImage class="color" folder="joints" :name="joint.file" />
            {{ joint.name }}
            <input type="radio" :id="joint.name" :value="joint" v-model="values.form.value.joint.type" />
          </label>
        </template>
      </div>
    </div>
  </div>
</template>
