import { dialog } from '#client/src/components/modal/modal.ts'
import { storage } from '#client/src/services/storage.ts'
import { api } from '#client/src/services/api.ts'
import { useForm } from '#client/src/services/form.ts'
import { reactive } from 'vue'
import { download } from '../..'

const BMN_USER_INFORMATION = '__bmn_user_information'

interface Information {
  first_name: string
  last_name: string
  email: string
}

export const errorMessage = reactive({
  show: false,
  message: 'Om de texturen te downloaden moet je eerst je gegevens invullen.'
})

export const [Information, showInformation, closeInformation] = dialog(() => import('./Modal.vue'))

const storedValues = storage.get<Information>(BMN_USER_INFORMATION)

export const values = useForm<Information>({
  first_name: storedValues?.first_name || '',
  last_name: storedValues?.last_name || '',
  email: storedValues?.email || ''
})

export async function submitInfomation() {
  storage.set(BMN_USER_INFORMATION, values.form.value)

  try {
    const data = await api.post('/api/users', { body: values.form.value })
    if (!data) {
      errorMessage.show = true
      return
    }

    closeInformation()
    download()
  } catch (error) {
    console.error(error)
  }
}
