import { createApp } from 'vue'
import { createFloating } from './directive/floating'
import { outside } from './services/outside'

import App from './App.vue'

import './assets/scss/index.scss'

const app = createApp(App)

const float = createFloating()

app.use(float)
app.use(outside)

app.mount('#app')
