import { useFloating } from '@/composables/floating'
import { markRaw, ref } from 'vue'

import type { App, DirectiveBinding } from 'vue'

declare global {
  interface EventTarget {
    contains: (value: HTMLElement) => boolean
  }
}

interface MapEvent {
  [key: string]: () => void
}

const DIRECTIVE_NAME = 'floating'

const eventMap: { [key: string]: (event: Event) => void } = {
  prevent: (event) => event.preventDefault(),
  stop: (event) => event.stopPropagation()
}

export function createFloating() {
  return {
    install(app: App) {
      app.directive(DIRECTIVE_NAME, {
        mounted(el: HTMLElement, binding: DirectiveBinding) {
          const { tooltip, show, hide, createInstance } = useFloating()

          const element = ref<HTMLElement | null>(null)

          function click() {
            createInstance(el, binding)

            el.addEventListener('click', (event) => {
              eventMap[binding.modifiers?.prevent ? 'prevent' : 'stop'](event)

              if (el.contains(event.target as Node)) {
                if (element.value === el) {
                  hide()
                  element.value = null
                } else {
                  if (element.value) hide(element.value)
                  element.value = el
                  show()
                }
              }
            })

            document.addEventListener('click', (event) => {
              eventMap[binding.modifiers?.prevent ? 'prevent' : 'stop'](event)

              if (!el.contains(event.target as Node) && !tooltip.value?.contains(event.target as Node)) {
                hide()
                if (element.value === el) element.value = null
              }
            })
          }

          function hover() {
            createInstance(el, binding)

            const showTooltip = (event: Event) => {
              eventMap[binding.modifiers?.prevent ? 'prevent' : 'stop'](event)

              if (element.value && element.value !== el) {
                hide(element.value)
              }
              element.value = el
              show()
            }

            const hideTooltip = () => {
              if (element.value === el) {
                hide()
                element.value = null
              }
            }

            el.addEventListener('mouseenter', showTooltip)

            Array.from(el.childNodes)
              .filter(({ nodeType }) => nodeType === Node.ELEMENT_NODE)
              .forEach(({ addEventListener }) => addEventListener('mouseenter', showTooltip))

            document.addEventListener('mouseover', (event) => {
              eventMap[binding.modifiers?.prevent ? 'prevent' : 'stop'](event)

              if (!tooltip.value?.contains(event.target as Node) && !el.contains(event.target as Node)) {
                hideTooltip()
              }
            })
          }

          const showEventMap = markRaw<MapEvent>({ click, hover })
          binding.arg ? showEventMap[binding.arg]() : hover()
        },
        unmounted() {
          useFloating().hide()
        }
      })
    }
  }
}
